WiseMetering.Views.ERedesIntegrationDetails = Backbone.Marionette.ItemView.extend({
    className: 'dashboard full-height',
    template: 'templates/integrations/e_redes/show/details',

    modelEvents: {
        'change': 'render'
    },

    events: {
        'click #edit_attributes': 'editAttributes'
    },

    editAttributes: function(event) {
        event.stopPropagation();
        new WiseMetering.Views.EditERedesIntegration({ model: this.model });
    },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            integrationIndicators: this.model.integrationIndicators()
        };
    }
});
