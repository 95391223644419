WiseMetering.Model.Integration = WiseMetering.Model.extend({
    urlRoot: '/integrations',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    configuration: function() {
        return WiseMetering.integrationConfigurations.get(this.get('configuration_id'));
    },

    disable: function(opts) {
        const options = {
            contentType: 'application/json',
            type: 'POST',
            url: `${this.url()}/disable`
        };

        _.extend(options, opts);

        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    fetchData: function(opts) {
        const options = {
            contentType: 'application/json',
            type: 'GET',
            url: `${this.url()}/data`
        };

        _.extend(options, opts);

        return (this.sync || Backbone.sync).call(this, null, this, options);
    },

    getLogs: function(from, to) {
        return $.ajax({
            url: '/integration_logs',
            data: { integration_id: this.id, from: from, to: to },
            dataType: 'json',
            type: 'GET',
            contentType: 'application/json'
        });
    },

    indicator: function() {
        return WiseMetering.indicators.get(this.get('indicator_id'));
    },

    indicatorKind: function() {
        return WiseMetering.indicatorKinds.findWhere({ slug: this.get('kind_slug') });
    },

    integrationIndicators: function() {
        return WiseMetering.indicators.where({ integration_id: this.id });
    },

    license: function() {
        return WiseMetering.integrationLicenses.get(this.get('license_id'));
    },

    partner: function() {
        return WiseMetering.integrationPartners.get(this.get('partner_id'));
    }
});
