WiseMetering.Views.NextbittMaintenanceConfigurationsTable = WiseMetering.Views.TableIndex.extend({
    icon: 'integrations',
    name: () => 'navigator',

    createTable: function() {
        this.collection = this.model.configurations();

        this.table = new WiseMetering.Views.Table({
            id: 'configurations',
            collection: this.collection,
            disableSelect: !WiseMetering.currentUser.isSuperUser(),
            modelSerializer: model => {
                let
                    color = 'orange',
                    state = model.get('state');

                if (state === 'active') {
                    color = 'green';
                }

                return {
                    building: WiseMetering.zones.buildings().get(model.get('building_id')).get('name'),
                    building_reference_name: model.get('building_reference_name') || '',
                    id: model.id,
                    name: model.get('name'),
                    state: { color, state },
                    updated_at: WiseMetering.utils.formatDate(model.get('updated_at'))
                };
            },
            columns: {
                state: {
                    mRender: ({ color, state }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: state.capitalize()
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                building: i18next.t('glossary.building'),
                building_reference_name: i18next.t('integrations.nextbitt_building'),
                updated_at: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                new: {
                    label: i18next.t('ui.actions.new'),
                    permission: 'superuser',
                    view_call: () => new WiseMetering.Views.NewNextbittMaintenanceConfiguration({
                        collection: WiseMetering.integrationConfigurations,
                        model: new WiseMetering.Model.IntegrationConfiguration({ license_id: this.model.id })
                    })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.EditNextbittMaintenanceConfiguration({ model })
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => new WiseMetering.Views.ShowNextbittMaintenanceConfiguration({ model })
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.DestroyExtraConfirmation({
                        model,
                        title: () => i18next.t('modal.title.integration_configurations.destroy')
                    })
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.integrationConfigurations);

        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationConfigurations, 'remove add change', this.createTable.bind(this));

        this.createTable();
    }
});
