module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_reference\'>' +
((__t = ( i18next.t('integrations.local_code') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_reference\' min=\'0\' name=\'integration[reference]\' type=\'number\'>\n        </dd>\n        <dt>\n            <label for=\'integration_consent\'>' +
((__t = ( i18next.t('integrations.consent') )) == null ? '' : __t) +
'?</label>\n        </dt>\n        <dd>\n            <input class=\'vertical-middle\' id=\'integration_consent\' name=\'integration[consent]\' type=\'checkbox\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}