WiseMetering.Views.ShowShopperTrakIntegration = WiseMetering.Views.Content.extend({
    icon: 'wiseStore',

    initialize({ model, view }) {
        const partner = model.partner();
        this.name = `${partner.get('name')} > ${this.model.get('name')}`;

        this.tabs = [
            {
                active: view === 'details',
                href: `/integrations/${model.id}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.ShopperTrakIntegrationDetails
            },
            {
                active: view === 'data',
                href: `/integrations/${model.id}/data`,
                id: 'data',
                name: i18next.t('common.data'),
                view: WiseMetering.Views.ShopperTrakIntegrationData
            }
        ];

        if (WiseMetering.currentUser.get('superuser')) {
            this.tabs.push({
                active: view === 'logs',
                href: `/integrations/${model.id}/logs`,
                id: 'logs',
                name: i18next.t('glossary.logs'),
                view: WiseMetering.Views.IntegrationLogs
            });
        }
    }
});
