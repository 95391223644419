WiseMetering.Views.ERedesIntegrationsTable = WiseMetering.Views.TableIndex.extend({
    icon: 'integrations',
    name: () => 'e_redes',

    createTable: function() {
        this.collection = this.model.integrations();

        this.table = new WiseMetering.Views.Table({
            id: 'integrations',
            collection: this.collection,
            modelSerializer: model => {
                let
                    color = 'orange',
                    state = model.get('state');

                if (state === 'active') {
                    color = 'green';
                }

                return {
                    buildingName: model.building()?.get('name'),
                    consent: i18next.t(`common.${model.get('consent') ? 'yes' : 'no'}`),
                    id: model.id,
                    lastProcessedFile: model.get('last_processed_file') || '-',
                    name: model.get('name'),
                    reactive: i18next.t(`common.${model.get('reactive') ? 'yes' : 'no'}`),
                    reference: model.get('reference'),
                    lastRunAt: model.get('last_run_at') ? WiseMetering.utils.formatDate(model.get('last_run_at')) : '-',
                    updatedAt: WiseMetering.utils.formatDate(model.get('updated_at')),
                    state: { color, state }
                };
            },
            columns: {
                state: {
                    mRender: ({ color, state }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title: state.capitalize()
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                reference: i18next.t('integrations.cpe'),
                lastProcessedFile: i18next.t('integrations.last_processed_file'),
                consent: `${i18next.t('integrations.consent')}?`,
                reactive: `${i18next.t('glossary.reactive')}?`,
                buildingName: i18next.t('glossary.building'),
                lastRunAt: i18next.t('integrations.last_run_at'),
                updatedAt: i18next.t('glossary.last_change')
            },
            toolbar_actions: {
                new: {
                    label: i18next.t('ui.actions.new'),
                    view_call: () => new WiseMetering.Views.NewERedesIntegration({
                        collection: WiseMetering.integrations,
                        license: this.model,
                        model: new WiseMetering.Model.Integration({ license_id: this.model.id })
                    })
                },
                edit: {
                    label: i18next.t('ui.actions.edit'),
                    view_call: model => new WiseMetering.Views.EditERedesIntegration({ model })
                },
                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => Backbone.history.navigate(`/integrations/${model.id}/details`, { trigger: true })
                },
                disable: {
                    icon: 'disable',
                    label: i18next.t('ui.actions.disable'),
                    view_call: model => new WiseMetering.Views.DisableIntegration({ model })
                },
                delete: {
                    permission: 'superuser',
                    view_call: model => new WiseMetering.Views.Modal.DestroyExtraConfirmation({
                        model,
                        title: () => i18next.t('modal.title.integrations.destroy')
                    })
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        this.stopListening(WiseMetering.integrations);

        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrations, 'remove add change', this.createTable.bind(this));

        this.createTable();
    }
});
