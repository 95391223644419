WiseMetering.Views.DisableIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/disable',
    title: () => i18next.t('modal.title.integrations.disable'),

    save: function() {
        this.modal.block();

        this.model.disable({
            success: function(data) {
                WiseMetering.integrations.fetch();
                WiseMetering.layout.showTipper('success', i18next.t('ui.successfully_saved'));
                this.modal.close();
            }.bind(this),
            error: function(jqXHR) {
                const errorMessage = jqXHR.responseJSON?.error || i18next.t('ui.unspecified_error');
                WiseMetering.layout.showTipper('error', errorMessage);
                this.modal.unblock();
            }.bind(this)
        });
    }
});
