WiseMetering.Views.IntegrationLogs = WiseMetering.Views.TableIndex.extend({
    template: 'templates/integration_logs/table',

    ui: {
        to: '#date_time_to',
        from: '#date_time_from'
    },

    createTable: function() {
        this.showLoader();

        this.model.getLogs(
            this.ui.from.val().replace(/\./g, '-'),
            this.ui.to.val().replace(/\./g, '-')
        ).done(logs => {
            const collection = new WiseMetering.Collection(logs);

            this.table = new WiseMetering.Views.Table({
                disableSelect: true,
                id: 'integration-logs',
                collection,
                modelSerializer: model => {
                    return {
                        level: model.get('level'),
                        message: model.get('message'),
                        created_at: WiseMetering.utils.formatDate(this.model.get('created_at'))
                    };
                },
                columns: {
                    level: i18next.t('common.level'),
                    message: i18next.t('common.message'),
                    created_at: i18next.t('glossary.created_at')
                }
            });

            $(this.body.el).find('div#integration-logs-table').html(this.table.el);
        });
    },

    onShow: function() {
        const
            from = moment().subtract(1, 'months').format('YYYY.MM.DD'),
            to = moment().format('YYYY.MM.DD');

        this.ui.from.val(from);
        this.ui.to.val(to);

        $(this.body.el).find('#submit_time').click(() => {
            this.updateFields();
            if (this.ui.from.val() && this.ui.to.val()) {
                this.createTable();
            }
        });

        this.ui.from.datepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            onSelect: selectedFrom => {
                this.ui.to.datepicker('option', 'minDate', selectedFrom);
            }
        });

        this.ui.to.datepicker({
            dateFormat: 'yy.mm.dd',
            changeMonth: true,
            changeYear: true,
            showOtherMonths: true,
            onSelect: selectedTo => {
                this.ui.from.datepicker('option', 'maxDate', selectedTo);
            }
        });

        this.createTable();
    },

    showLoader: function() {
        const loader = '<img class="componentLoading" src="/images/wisemetering/loader.svg" style="margin: 0 auto; max-height: 50px; width: 100%;">';
        $(this.body.el).find('div#integration-logs-table').html(loader);
    },

    updateFields: function() {
        this.ui.from.datepicker('option', 'maxDate', this.ui.to.val());
        this.ui.to.datepicker('option', 'minDate', this.ui.from.val());
    }
});
