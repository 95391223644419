WiseMetering.Views.EditMeasurablConfiguration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration_configuration',
    template: 'templates/integration_configurations/measurabl/edit',
    title: () => i18next.t('modal.title.integration_configurations.edit'),

    getFormData: function() {
        const data = this.formSerializer();
        if (!data.password) {
            delete data.password;
        }
        return data;
    }
});
