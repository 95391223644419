WiseMetering.Views.NewNextbittMaintenanceIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/nextbitt_maintenance/new',
    title: () => i18next.t('modal.title.integrations.new'),

    ui: {
        assetsList: '#assets-list',
        buildings: '#building_id',
        indicators: '#indicator',
        configurations: '#configuration_id',
        indicator: '#indicator_id',
        name: 'input#integration_name',
        search: 'input#asset-search',
        spinner: '#spinner'
    },

    events: {
        'keyup input#asset-search': 'search',
        'change @ui.buildings': 'onBuildingChange'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);

        this.partner_id = WiseMetering.integrationLicenses.get(this.model.get('license_id')).get('partner_id');
    },

    fetchExternalAssets: function(searchText) {
        this.modal.block();

        const payload = {
            license_id: this.model.get('license_id'),
            partner_id: this.partner_id,
            building_id: this.ui.buildings.val(),
            configuration_id: this.ui.configurations.val(),
            indicator_id: this.ui.indicators.val(),
            search_text: searchText
        };

        $.ajax({
            url: `/integrations/external_assets`,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: function(data) {
                const selectedElements = $('#assets-list').children('input:checked');

                selectedElements.each(function () {
                    const asset = $(this).add($(this).siblings());

                    asset.clone(true).appendTo('#current-assets-list');
                });

                this.ui.assetsList.empty();

                if (data.length > 0) {
                    data.forEach(function(asset, index) {
                        this.ui.assetsList.append(
                            `<input class="vertical-middle asset-checkbox"
                                    id="integration[assets_attributes][${index}]"
                                    name="integration[assets_attributes][]"
                                    type="checkbox"
                                    value="${asset.asset_reference}"
                                    data-name="${asset.name}"
                                    data-asset-reference="${asset.asset_reference}"
                                    data-local-reference="${asset.local_reference}">
                            </input>`,
                            `<label for='integration[assets_attributes][${index}]' style='margin-left: 5px'>${asset.name}</label><br/>`
                        );
                    }.bind(this));

                    $('#save').prop('disabled', false);
                } else {
                    this.ui.assetsList.html(`<p>${i18next.t('integration_assets.no_assets')}</p>`);
                }

                this.modal.unblock();
            }.bind(this),
            error: function(err) {
                const errors = err.responseJSON || null;
                let errorMessage = '';

                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        errorMessage += `${key.capitalize()}: ${errors[key].capitalize()} <br>`;
                    });
                } else {
                    errorMessage = 'An error occurred';
                }

                WiseMetering.layout.showTipper('error', errorMessage);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        let data = this.formSerializer();
        const assets_attributes = [];

        this.$('.asset-checkbox:checked').each(function() {
            const $asset = $(this);
            const asset_info = {
                name: $asset.data('name'),
                asset_reference: $asset.data('asset-reference'),
                local_reference: $asset.data('local-reference')
            };

            if (!assets_attributes.some(a => a.asset_reference === asset_info.asset_reference)) {
                assets_attributes.push(asset_info);
            }
        });

        data.assets_attributes = assets_attributes;
        data.partner_id = this.partner_id;

        return data;
    },

    onBuildingChange: function() {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.configurations.empty();
            this.ui.indicators.empty();
            return;
        }

        this.ui.spinner.show();
        const indicators = WiseMetering.utils.optionsFromModels(WiseMetering.indicators.where({ building_id: selectedBuildingId }));
        const configurations = WiseMetering.utils.optionsFromModels(WiseMetering.integrationConfigurations.where({ building_id: selectedBuildingId }));

        this.ui.indicators.html(indicators);
        this.ui.configurations.html(configurations);
    },

    onShow: function() {
        const buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.buildings().models);
        const defaultOption = `<option disabled selected>${i18next.t('ui.please_select_option')}</option>`;
        $('#save').prop('disabled', true);

        this.ui.buildings.html(defaultOption + buildings);
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        if (this.ui.search.val().length < 3) {
            return;
        }

        this.fetchExternalAssets(this.ui.search.val());
    }
});
