// CSS
require('./css/wisemetering.css');

const sync = Backbone.sync;
Backbone.sync = (method, model, opts) => sync(method === 'patch' ? 'update' : method, model, opts);

// Services
req = require.context('./js/services', true);
req.keys().forEach(req);

// Base App
require('./js/ims');
require('./js/wisemetering/extensions.js');
require('./js/wisemetering/init.js');
require('./js/wisemetering/utils.js');
require('./js/wisemetering/analytics.js');
require('./js/wisemetering/icons.js');
require('./js/wisemetering/number_formatter.js');

// Models
require('./js/wisemetering/actuator_kinds/model.js');
require('./js/wisemetering/actuators/model.js');
require('./js/wisemetering/addresses/model.js');
require('./js/wisemetering/alarm_schedules/model.js');
require('./js/wisemetering/alarms/model.js');
require('./js/wisemetering/allocations/model.js');
require('./js/wisemetering/analyze_views/model.js');
require('./js/wisemetering/building_tags/model.js');
require('./js/wisemetering/buildings/model.js');
require('./js/wisemetering/circuit_tags/model.js');
require('./js/wisemetering/circuits/model.js');
require('./js/wisemetering/contracts/model.js');
require('./js/wisemetering/companies/model.js');
require('./js/wisemetering/devices/model.js');
require('./js/wisemetering/events/model.js');
require('./js/wisemetering/etls/model.js');
require('./js/wisemetering/external_assets/model.js');
require('./js/wisemetering/folders/model.js');
require('./js/wisemetering/global_report_types/model.js');
require('./js/wisemetering/groups/model.js');
require('./js/wisemetering/holidays/model.js');
require('./js/wisemetering/indicators/model.js');
require('./js/wisemetering/invoices/model.js');
require('./js/wisemetering/integration_configurations/model.js');
require('./js/wisemetering/integration_licenses/model.js');
require('./js/wisemetering/integration_license_logs/model.js');
require('./js/wisemetering/integration_partners/model.js');
require('./js/wisemetering/integrations/model.js');
require('./js/wisemetering/markers/model.js');
require('./js/wisemetering/messages/model.js');
require('./js/wisemetering/objectives/model.js');
require('./js/wisemetering/opportunities/model.js');
require('./js/wisemetering/organizations/model.js');
require('./js/wisemetering/organization_logs/model.js');
require('./js/wisemetering/packages/model.js');
require('./js/wisemetering/reports/model.js');
require('./js/wisemetering/root_objects/model.js');
require('./js/wisemetering/seus/model.js');
require('./js/wisemetering/scheduled_reports/model.js');
require('./js/wisemetering/site_packages/model.js');
require('./js/wisemetering/tariffs/model.js');
require('./js/wisemetering/tariff_types/model.js');
require('./js/wisemetering/tars/model.js');
require('./js/wisemetering/tenants/model.js');
require('./js/wisemetering/tenant_invoices/model.js');
require('./js/wisemetering/tiers/model.js');
require('./js/wisemetering/users/model.js');
require('./js/wisemetering/user_preferences/model.js');
require('./js/wisemetering/utilities/model.js');
require('./js/wisemetering/utility_kinds/model.js');
require('./js/wisemetering/zone_attribute_values/model.js');
require('./js/wisemetering/zone_attributes/model.js');
require('./js/wisemetering/zone_report_types/model.js');
require('./js/wisemetering/zones/model.js');

// Collections
require('./js/wisemetering/actuator_kinds/collection.js');
require('./js/wisemetering/actuators/collection.js');
require('./js/wisemetering/alarm_schedules/collection.js');
require('./js/wisemetering/alarms/collection.js');
require('./js/wisemetering/allocations/collection.js');
require('./js/wisemetering/analyze_views/collection.js');
require('./js/wisemetering/api_requests/collection.js');
require('./js/wisemetering/building_tags/collection.js');
require('./js/wisemetering/circuit_tags/collection.js');
require('./js/wisemetering/circuits/collection.js');
require('./js/wisemetering/contracts/collection.js');
require('./js/wisemetering/companies/collection.js');
require('./js/wisemetering/devices/collection.js');
require('./js/wisemetering/events/collection.js');
require('./js/wisemetering/etls/collection.js');
require('./js/wisemetering/external_assets/collection.js');
require('./js/wisemetering/folders/collection.js');
require('./js/wisemetering/global_report_types/collection.js');
require('./js/wisemetering/groups/collection.js');
require('./js/wisemetering/holidays/collection.js');
require('./js/wisemetering/indicators/collection.js');
require('./js/wisemetering/integration_configurations/collection.js');
require('./js/wisemetering/integration_licenses/collection.js');
require('./js/wisemetering/integration_license_logs/collection.js');
require('./js/wisemetering/integration_partners/collection.js');
require('./js/wisemetering/integrations/collection.js');
require('./js/wisemetering/invoices/collection.js');
require('./js/wisemetering/markers/collection.js');
require('./js/wisemetering/messages/collection.js');
require('./js/wisemetering/objectives/collection.js');
require('./js/wisemetering/opportunities/collection.js');
require('./js/wisemetering/organization_logs/collection.js');
require('./js/wisemetering/packages/collection.js');
require('./js/wisemetering/reports/collection.js');
require('./js/wisemetering/root_objects/collection.js');
require('./js/wisemetering/seus/collection.js');
require('./js/wisemetering/scheduled_reports/collection.js');
require('./js/wisemetering/site_packages/collection.js');
require('./js/wisemetering/tariffs/collection.js');
require('./js/wisemetering/tariff_types/collection.js');
require('./js/wisemetering/tars/collection.js');
require('./js/wisemetering/tenants/collection.js');
require('./js/wisemetering/tenant_invoices/collection.js');
require('./js/wisemetering/tiers/collection.js');
require('./js/wisemetering/users/collection.js');
require('./js/wisemetering/utilities/collection.js');
require('./js/wisemetering/utility_kinds/collection.js');
require('./js/wisemetering/zone_attribute_values/collection.js');
require('./js/wisemetering/zone_attributes/collection.js');
require('./js/wisemetering/zone_report_types/collection.js');
require('./js/wisemetering/zones/collection.js');

// Templates
require('./templates');

// Views
require('./js/wisemetering/ui/cards_view.js');
require('./js/wisemetering/ui/charts/scatter.js');
require('./js/wisemetering/ui/crud/base.js');
require('./js/wisemetering/ui/crud/save.js');
require('./js/wisemetering/ui/layouts/login.js');
require('./js/wisemetering/ui/layouts/root.js');
require('./js/wisemetering/ui/tipper.js');
require('./js/wisemetering/ui/content.js');
require('./js/wisemetering/ui/node.js');
require('./js/wisemetering/ui/buildings.js');
require('./js/wisemetering/ui/costs.js');
require('./js/wisemetering/ui/global_dashboard.js');
require('./js/wisemetering/ui/global_dashboard_header.js');
require('./js/wisemetering/ui/modal/base.js');
require('./js/wisemetering/ui/modal/custom.js');
require('./js/wisemetering/ui/modal/destroy.js');
require('./js/wisemetering/ui/modal/destroy_extra_confirmation.js');
require('./js/wisemetering/ui/modal/save.js');
require('./js/wisemetering/ui/modal/show.js');
require('./js/wisemetering/ui/modal/loading.js');
require('./js/wisemetering/ui/upload_data.js');
require('./js/wisemetering/ui/map/building_popup.js');
require('./js/wisemetering/ui/map/index.js');
require('./js/wisemetering/ui/map/search.js');
require('./js/wisemetering/ui/map/styles.js');
require('./js/wisemetering/ui/no_access/no_access.js');
require('./js/wisemetering/ui/no_access/no_access_message.js');
require('./js/wisemetering/ui/option/view.js');
require('./js/wisemetering/ui/schedule/base.js');
require('./js/wisemetering/ui/schedule/circuit_schedule.js');
require('./js/wisemetering/ui/schedule/alarm_schedule.js');
require('./js/wisemetering/ui/select/view.js');
require('./js/wisemetering/ui/table/index.js');
require('./js/wisemetering/ui/table/view.js');
require('./js/wisemetering/ui/table/delete_all.js');
require('./js/wisemetering/ui/table/generate.js');
require('./js/wisemetering/ui/card/index.js');
require('./js/wisemetering/ui/card/item.js');
require('./js/wisemetering/ui/card/container.js');
require('./js/wisemetering/ui/widget/base.js');
require('./js/wisemetering/ui/widget/co2_displayer.js');
require('./js/wisemetering/ui/widget/bar_chart.js');
require('./js/wisemetering/ui/widget/benchmark.js');
require('./js/wisemetering/ui/widget/bubble_chart.js');
require('./js/wisemetering/ui/widget/carousel_pie.js');
require('./js/wisemetering/ui/widget/cost.js');
require('./js/wisemetering/ui/widget/dashboard_map.js');
require('./js/wisemetering/ui/widget/distribution.js');
require('./js/wisemetering/ui/widget/global_objectives.js');
require('./js/wisemetering/ui/widget/no_data.js');
require('./js/wisemetering/ui/widget/number_displayer.js');
require('./js/wisemetering/ui/widget/objective.js');
require('./js/wisemetering/ui/widget/omie.js');
require('./js/wisemetering/ui/widget/pie_chart.js');
require('./js/wisemetering/ui/widget/power.js');
require('./js/wisemetering/ui/widget/table.js');
require('./js/wisemetering/ui/widget/trend.js');
require('./js/wisemetering/ui/widget/value_displayer.js');
require('./js/wisemetering/ui/widget/widget_options.js');
require('./js/wisemetering/ui/system_info_accordion.js');

let req;
req = require.context('./js/wisemetering/actuators/views/', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/admin/views/', true);
req.keys().forEach(req);
require('./js/wisemetering/alarm_schedules/views/new.js');
req = require.context('./js/wisemetering/alarm_schedules/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/alarms/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/allocations/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/api_keys/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/api_requests/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/buildings/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/building_tags/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/site_packages/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/circuit_tags/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/circuits/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/companies/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/contracts/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/devices/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/events/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/etls/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/etls/views/types', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/markers/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/folders/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/global_indicators/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/groups/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/holidays/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/indicators/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/indicators/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/invoices/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/integration_configurations/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/integration_licenses/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/integration_partners/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/integrations/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/messages/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/objectives/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/opportunities/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/organizations/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/organization_logs/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/readings/views', true);
req.keys().forEach(req);
require('./js/wisemetering/scheduled_reports/views/index.js');
req = require.context('./js/wisemetering/reports/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/root_objects/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/seus/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/scheduled_reports/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/tariffs/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/tars/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/tenants/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/tenant_invoices/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/users/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/utilities/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/zone_attributes/views', true);
req.keys().forEach(req);
req = require.context('./js/wisemetering/zones/views', true);
req.keys().forEach(req);

// Start
require('./js/wisemetering/controller.js');
require('./js/wisemetering/router.js');
require('./js/wisemetering/glue.js');
