WiseMetering.Views.NewNextbittDataIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/nextbitt_data/new',
    ui: {
        buildings: '#integration_building_id',
        configurations: '#integration_configuration_id',
        indicatorKinds: '#integration_kind_slug',
        startFetchingAt: '#integration_start_fetching_at'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onRender: function() {
        const maxRetention = WiseMetering.currentOrganization.tier().get('data_retention');

        this.ui.startFetchingAt.datepicker({
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true,
            maxDate: moment().endOf('year').format('YYYY-MM-DD'),
            minDate: moment().add(-maxRetention, 'years').startOf('year').format('YYYY-MM-DD')
        });
    },

    onShow: function() {
        const
            buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.activeSites().models, 'name'),
            configurations = WiseMetering.utils.optionsFromModels(this.options.license.configurations().models, 'name'),
            indicatorKinds = WiseMetering.utils.optionsFromArray(WiseMetering.indicatorKinds.map(kind => ({
                id: kind.get('slug'),
                name: kind.get('name')
            })));

        this.ui.buildings.html(buildings);
        this.ui.configurations.html(configurations);
        this.ui.indicatorKinds.html(indicatorKinds);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        if (data.ratio) {
            data.ratio = parseFloat(data.ratio);
        }
        return data;
    }
});
