import { Input, TextArea } from '@/components/wm';
import * as React from 'react';

export function ModelProperty({ value, dataType = 'string', label, onChange, property, type = 'input' }) {
    const handleValueChange = rawValue => {
        let parsedValue;

        switch (dataType) {
            case 'int':
                parsedValue = rawValue ? parseInt(rawValue, 10) : null;
                break;
            case 'float':
                parsedValue = rawValue ? parseFloat(rawValue) : null;
                break;
            case 'boolean':
                parsedValue = rawValue === 'true' || rawValue === true;
                break;
            case 'string':
            default:
                parsedValue = rawValue; // Default to string
                break;
        }

        onChange(parsedValue);
    };

    const renderInput = () => {
        switch (type) {
            case 'hidden':
                return (
                        <input
                                id={property}
                                name={property}
                                type="hidden"
                                value={value || ''}
                        />
                );
            case 'number':
                return (
                        <Input
                                className="tw-w-full"
                                id={property}
                                name={property}
                                onChange={event => handleValueChange(event.target.value)}
                                type="number"
                                value={value || ''}
                        />
                );
                // case "select":
                //     return (
                //         <Select
                //             id={property}
                //             name={property}
                //             value={value || ""}
                //             onChange={(e) => onChange(e.target.value)}
                //             className="tw-w-full"
                //         >
                //             {options.map((option) => (
                //                 <option key={option.value} value={option.value}>
                //                     {option.label}
                //                 </option>
                //             ))}
                //         </Select>
                //     );
                // case "multipleSelect":
                //     return (
                //         <Select
                //             id={property}
                //             name={property}
                //             value={value || []}
                //             onChange={(e) => onChange([...e.target.selectedOptions].map(o => o.value))}
                //             multiple
                //             className="tw-w-full"
                //         >
                //             {options.map((option) => (
                //                 <option key={option.value} value={option.value}>
                //                     {option.label}
                //                 </option>
                //             ))}
                //         </Select>
                //     );
            case 'text':
                return (
                        <TextArea
                                id={property}
                                name={property}
                                value={value || ''}
                                onChange={event => handleValueChange(event.target.value)}
                                className="tw-w-full"
                        />
                );
                // case "checkBox":
                //     return (
                //         <Checkbox
                //             id={property}
                //             name={property}
                //             checked={!!value}
                //             onChange={(e) => onChange(e.target.checked)}
                //             className="tw-w-full"
                //         />
                //     );
                // case "radio":
                //     return (
                //         <div>
                //             {options.map((option) => (
                //                 <label key={option.value} className="tw-inline-flex tw-items-center tw-space-x-2">
                //                     <Radio
                //                         id={`${property}-${option.value}`}
                //                         name={property}
                //                         value={option.value}
                //                         checked={value === option.value}
                //                         onChange={(e) => onChange(e.target.value)}
                //                         className="tw-mr-2"
                //                     />
                //                     {option.label}
                //                 </label>
                //             ))}
                //         </div>
                //     );
            case 'input':
            default:
                return (
                        <Input
                                id={property}
                                name={property}
                                value={value || ''}
                                onChange={event => handleValueChange(event.target.value)}
                                className="tw-w-full"
                        />
                );
        }
    };

    label ??= property.charAt(0).toUpperCase() + property.slice(1);

    return (
            <div className="tw-space-y-2">
                {type !== 'hidden' && (
                        <label htmlFor={property} className="tw-text-sm tw-font-medium">
                            {label}
                        </label>
                )}
                {renderInput()}
            </div>
    );
}

