WiseMetering.Model.ZoneAttribute = WiseMetering.Model.extend({
    urlRoot: '/zone_attributes',

    unit: function() {
        return WiseMetering.units.get(this.get('unit_id'));
    },

    symbol: function() {
        const symbol = this.unit().get('symbol');
        return symbol === 'N/A' ? null : symbol;
    }
});
