module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_name\' name=\'integration[name]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_id\'>' +
((__t = ( i18next.t('glossary.configuration') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_configuration_id\' name=\'integration[configuration_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_kind_slug\'>' +
((__t = ( i18next.t('glossary.indicator_kind') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_kind_slug\' name=\'integration[kind_slug]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_ratio\'>' +
((__t = ( i18next.t('glossary.ratio') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_ratio\' name=\'integration[ratio]\' step=\'0.001\' type=\'number\'>\n        </dd>\n        <dt>\n            <label for=\'integration_reference\'>' +
((__t = ( i18next.t('common.reference') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_reference\' name=\'integration[reference]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_start_fetching_at\'>' +
((__t = ( i18next.t('integrations.start_fetching_at') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_start_fetching_at\' name=\'integration[start_fetching_at]\' readonly=\'readonly\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}