WiseMetering.Views.NewElectricityMapsIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/electricity_maps/new',
    ui: {
        buildings: '#building_id',
        indicators: '#integration_indicator_id'
    },
    events: {
        'change #integration_emission_factor_type': 'updateDescription',
        'change @ui.buildings': 'onBuildingChange'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    },

    onBuildingChange: function() {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.indicators.empty();
            return;
        }

        const
            usedIndicatorsIds = this.model.license().indicators().pluck('id'),
            availableIndicators = WiseMetering.indicators.models.filter(function (indicator) {
                const
                    activeEnergy = indicator.get('kind_slug') === 'active_energy',
                    inBuilding = indicator.get('building_id') === selectedBuildingId,
                    notUsed = !usedIndicatorsIds.includes(indicator.id);

                return activeEnergy && inBuilding && notUsed;
            }),
            indicators = WiseMetering.utils.optionsFromModels(availableIndicators, 'name', null, availableIndicators.length !== 1);

        this.ui.indicators.html(indicators);
    },

    onShow: function() {
        this.updateDescription();

        const
            buildings = WiseMetering.zones.activeSites().filter(building => building.get('timezone') === 'Europe/Lisbon');

        this.ui.buildings.html(WiseMetering.utils.optionsFromModels(buildings, 'name', null, buildings.length !== 1));
        this.onBuildingChange();
    },

    updateDescription: function() {
        const
            selectedType = this.$('#integration_emission_factor_type').val(),
            description = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_description`),
            title = i18next.t(`integration_partners.partners.electricity_maps.${selectedType}_title`),
            warningContainer = this.$('#emission-factor-container'),
            warningElement = this.$('#emission-factor-warning');

        if (selectedType === 'direct' && warningElement.length === 0) {
            const warningText = i18next.t(`integration_partners.partners.electricity_maps.warning`);

            warningContainer.prepend(`<p id="emission-factor-warning" class="modal-description-warning">${warningText}</p>`);
        } else {
            warningElement.remove();
        }

        this.$('#emission-factor-title').text(title);
        this.$('#emission-factor-description').text(description);
    }
});
