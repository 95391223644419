import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Button, Card, CardContent, CardHeader, CardStat, CardWrapper, Container, ContentArea, ManageLicense, ModelProperty } from '@/components/wm';
import { Plus } from 'lucide-react';
import * as React from 'react';
import { useState } from 'react';

export default function IntegrationLicenses({ licenses, licenseLogs }) {
    const [modalOpen, setModalOpen] = useState(false);

    const licenses_total = licenses.total;

    let AddLicensesButton;

    if (WiseMetering.currentUser.isSuperUser()) {
        AddLicensesButton = (
                <Button aria-label='Add Licenses' onClick={() => setModalOpen(true)} size='icon' variant='ghost' className='!tw-max-h-28'>
                    <Plus className='tw-w-5 tw-h-5'/>
                </Button>
        );
    }

    return (
            <Container variant='full'>
                {modalOpen && (
                        <ManageLicense
                                onClose={() => setModalOpen(false)}
                                model={licenses}
                                mode='edit'
                                title={i18next.t('integrations.licenses.add_licenses')}
                                description={i18next.t('integrations.licenses.current_licenses_description', {licenses_total})}
                        >
                            <ModelProperty label={i18next.t('integrations.licenses.number_new_licenses')} property='new_licenses' type='number' dataType='int'/>
                            <ModelProperty label={i18next.t('common.comment').capitalize()} property='comment' type='text'/>
                            <ModelProperty property='partner_id' value={licenses.partner_id} type='hidden'/>
                            <ModelProperty property='organization_id' value={licenses.organization_id} type='hidden'/>
                        </ManageLicense>
                )}
                <ContentArea>
                    {/* Small Cards Section */}
                    <CardWrapper cardSize='small'>
                        <Card>
                            <CardHeader button={AddLicensesButton} title={i18next.t('common.available').capitalize()}/>
                            <CardContent>
                                <CardStat>{licenses.available}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={i18next.t('glossary.active_plural_f').capitalize()}/>
                            <CardContent>
                                <CardStat>{licenses.used}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={i18next.t('integrations.licenses.contracted_licenses')}/>
                            <CardContent>
                                <CardStat>{licenses.total}</CardStat>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader
                                    title={i18next.t('integrations.licenses.active_since')}
                            />
                            <CardContent>
                                <CardStat>{WiseMetering.utils.formatDate(licenses.created_at, null, 'YYYY/MM/DD')}</CardStat>
                            </CardContent>
                        </Card>
                    </CardWrapper>
                    {/* Table Section */}
                    <div className='tw-pt-4 tw-overflow-x-auto tw-w-full'>
                        <Table className='tw-min-w-full'>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>{i18next.t('glossary.created_at').capitalize()}</TableHead>
                                    <TableHead>{i18next.t('common.description').capitalize()}</TableHead>
                                    <TableHead>{i18next.t('common.comment').capitalize()}</TableHead>
                                    <TableHead>{i18next.t('integrations.licenses.contracted_licenses')}</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {licenseLogs.map(log => (
                                        <TableRow key={log.id}>
                                            <TableCell>{WiseMetering.utils.formatDate(log.created_at)}</TableCell>
                                            <TableCell>{log.description}</TableCell>
                                            <TableCell>{log.comment}</TableCell>
                                            <TableCell>{log.new_total}</TableCell>
                                        </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </ContentArea>
            </Container>
    );
}
