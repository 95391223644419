WiseMetering.Views.NewDataPortalIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/data_portal/new',
    ui: {
        buildings: '#integration_building_id',
        configurations: '#integration_configuration_id',
        indicatorKinds: '#integration_kind_slug'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onShow: function() {
        const
            buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.activeSites().models, 'name'),
            configurations = WiseMetering.utils.optionsFromModels(this.options.license.configurations().models, 'name'),
            indicatorKinds = WiseMetering.utils.optionsFromArray(WiseMetering.indicatorKinds.map(kind => ({
                id: kind.get('slug'),
                name: kind.get('name')
            })));

        this.ui.buildings.html(buildings);
        this.ui.configurations.html(configurations);
        this.ui.indicatorKinds.html(indicatorKinds);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        if (data.ratio) {
            data.ratio = parseFloat(data.ratio);
        }
        return data;
    }
});
