module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="' +
((__t = ( schedule_id ? 'width:200px; max-width:200px; min-width:200px; float:left; padding:25px 15px 0 10px;' : '' )) == null ? '' : __t) +
'">\n    <dl class=\'stepped zero\'>\n        ';
 if (WiseMetering.currentUser.isSuperUser()) { ;
__p += '\n            <dt>ID</dt>\n            <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n        <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('glossary.active') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( active ? i18next.t('common.yes') : i18next.t('common.no') )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( indicator )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('common.type') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( type )) == null ? '' : __t) +
'</dd>\n        ';
 if (notifications.length) { ;
__p += '\n            <dt>' +
((__t = ( i18next.t('glossary.notifications') )) == null ? '' : __t) +
'</dt>\n            <dd>' +
((__t = ( notifications )) == null ? '' : __t) +
'</dd>\n        ';
 } ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.created_at') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n        <dt>' +
((__t = ( i18next.t('ui.table.last_checked') )) == null ? '' : __t) +
'</dt>\n        <dd>' +
((__t = ( last_checked_humanized )) == null ? '' : __t) +
'</dd>\n        ';
 if (Object.keys(integration_assets).length > 0){ ;
__p += '\n            <div class="alarm-integration-assets"></div>\n            <dt>\n                Integrated Assets\n            </dt>\n            <div class="content">\n                <ul>\n                    ';
 integration_assets.forEach(function(asset) { ;
__p += '\n                        <li>\n                            <span class="truncated-text" title="' +
((__t = ( asset.name )) == null ? '' : __t) +
'">' +
((__t = ( asset.name )) == null ? '' : __t) +
'</span>\n                            <span class="question-mark">\n                                    <span class="tooltiptext">' +
((__t = ( asset.name )) == null ? '' : __t) +
'</span>\n                                    <i class="' +
((__t = (WiseMetering.icons.questionCircle)) == null ? '' : __t) +
'" aria-hidden=\'true\'></i>\n                                </span>\n                        </li>\n                    ';
 }) ;
__p += '\n                </ul>\n            </div>\n</div>\n';
 } ;
__p += '\n</dl>\n</div>\n<div style=\'float:left; padding-left:25px; border-left:1px solid #CCCCCC;\'>\n    <dl class=\'stepped\' id=\'alarm-schedule\'>\n        <dt style=\'margin-top: 15px;\'>\n            <label>' +
((__t = ( i18next.t('ui.table.alarm_schedule') )) == null ? '' : __t) +
'</label>\n        </dt>\n    </dl>\n</div>\n';

}
return __p
}