    WiseMetering.Model.Indicator = WiseMetering.Model.extend({
    urlRoot: '/indicators',

    defaults: {
        kind_id: null,
        name: null,
        operation: null,
        type: null
    },

    indicatorKindSlugToUtilityKindSlug: {
        'active_energy': 'electricity',
        'water': 'water',
        'gas_volume': 'gas_volume',
        'gas_energy': 'gas_energy',
        'hot_water_energy': 'hot_water_energy',
        'cooled_water_energy': 'cooled_water_energy',
        'oil': 'oil'
    },

    activeEnergy: function() {
        return this.kind().get('name') === 'Active Energy';
    },

    etl: function() {
        return WiseMetering.etls.get(this.get('etl_id'));
    },

    type: function() {
        return 'indicator';
    },

    icon: function() {
        return 'indicators';
    },

    bindChange: function() {
        this.on('change:parent_id', function() {
            if (this.previousParent()) {
                this.previousParent().trigger('change:children:removeIndicator', this);
            }
            if (this.parent()) {
                this.parent().trigger('change:children:addIndicator', this);
            }
        });
    },

    nodeUrl: function(skipSearch) {
        return `/indicators/${this.id}/plot${skipSearch ? '' : location.search}`;
    },

    children: function() {
        return new Backbone.Collection;
    },

    previousParent: function() {
        return WiseMetering.zones.get(this.previous('parent_id')) || WiseMetering.circuits.get(this.previous('parent_id'));
    },

    parent: function() {
        return WiseMetering.zones.get(this.get('parent_id')) || WiseMetering.circuits.get(this.get('parent_id'));
    },

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    zone: function() {
        const parent = this.parent();
        return parent instanceof WiseMetering.Model.Circuit ? parent.parent() : parent;
    },

    circuit: function() {
        const parent = this.parent();
        return parent instanceof WiseMetering.Model.Circuit ? parent : null;
    },

    processOperation: function(attribute, withLinks = false) {
        const regex = new RegExp(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g);

        return this.get(attribute).replace(regex, function(id) {
            const indicator = WiseMetering.indicators.get(id);

            if (!indicator && this.get('type') === 'mirror') {
                return '';
            }

            const name = indicator.get('name');

            if (withLinks) {
                return `<a href="${indicator.nodeUrl()}">${name}</a>`;
            } else {
                return name;
            }
        }.bind(this));
    },

    objectives: function() {
        return new WiseMetering.Collection.Objectives(WiseMetering.objectives.where({ indicator_id: this.id }));
    },

    operation_name: function(withLinks = false) {
        return this.processOperation('operation', withLinks);
    },

    raw_operation_name: function(withLinks = false) {
        return this.processOperation('raw_operation', withLinks);
    },

    rebuild: function() {
        return $.ajax({
            url: this.url() + '/rebuild',
            type: 'PUT'
        });
    },

    getMetals: function(from, to, mode = 'standard') {
        return $.ajax({
            url: this.url() + '/metals',
            data: { from: from, to: to, mode },
            dataType: 'json',
            type: 'GET',
            contentType: 'application/json'
        });
    },

    deleteMetals: function(metals) {
        return $.ajax({
            url: this.url() + '/delete_metals',
            dataType: 'json',
            data: JSON.stringify({ metals: metals }),
            type: 'DELETE',
            contentType: 'application/json'
        });
    },

    editMetal: function(payload) {
        return $.ajax({
            url: this.url() + '/edit_metal',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(payload),
            type: 'PUT'
        });
    },

    // API functions to retrieve data
    //
    // All the functions receive the params and both a success and error function
    //
    // Error data params: none
    //
    getErrorIntervals: function(success, error) {
        this.fetchIndicatorData('/errors', {}, success, error);
    },
    //
    // Realtime data params: { from: from, to: to, rate: rate }
    //
    getRealtimeData: function(params, success, error) {
        return this.fetchIndicatorData('/data', { ...params, mode: 'realtime', components: false }, success, error);
    },
    //
    // If no error function is provided, it will fail silently
    //
    fetchIndicatorData: function(url, params, success, error) {
        return $.ajax({ url: this.url() + url, type: 'GET', dataType: 'json', contentType: 'application/json', data: params, success, error });
    },

    folders: function() {
        return WiseMetering.folders.filter(folder => folder.get('indicator_ids').includes(this.id));
    },

    hasFolder: function() {
        return WiseMetering.folders.find(folder => folder.get('indicator_ids').includes(this.id));
    },

    hasUtility: function() {
        const building = this.building(), utilityKind = this.utilityKind();

        return WiseMetering.utilities.findWhere({ building_id: building?.id, utility_kind_id: utilityKind?.id });
    },

    folderNames: function(withLinks = false) {
        return this.folders().map(function(folder) {
            const name = folder.get('name');

            if (withLinks) {
                return `<a href="${folder.nodeUrl()}">${name}</a>`;
            } else {
                return name;
            }
        }.bind(this));
    },

    kind: function() {
        return WiseMetering.indicatorKinds.get(this.get('kind_id'));
    },

    cumulative: function() {
        return this.kind().get('cumulative');
    },

    participations: function() {
        return new WiseMetering.Collection(WiseMetering.indicators.filter(function(indicator) {
            return indicator.get('operation').match(this.id) && indicator.id !== this.id;
        }.bind(this)));
    },

    participationsName: function(withLinks = false) {
        return this.participations().map(function(indicator) {
            const name = indicator.get('name');

            if (withLinks) {
                return `<a href="${indicator.nodeUrl()}">${name}</a>`;
            } else {
                return name;
            }
        }.bind(this));
    },

    timezone: function() {
        const building = WiseMetering.indicators.get(this.id).building();
        return building ? building.get('timezone') : WiseMetering.currentOrganization.get('timezone');
    },

    utilityKindSlug: function() {
        return this.indicatorKindSlugToUtilityKindSlug[this.get('kind_slug')];
    },

    utilityKind: function() {
        return WiseMetering.utilityKinds.findWhere({ slug: this.utilityKindSlug() });
    }
});
