WiseMetering.Views.ShowNextbittMaintenanceConfiguration = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/integration_configurations/nextbitt_maintenance/show',
    title: () => i18next.t('modal.title.integration_configurations.show'),

    // edit: function() {
    //     new WiseMetering.Views.EditNextbittMaintenanceConfiguration({ model: this.model });
    // },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building()
        };
    }

});
