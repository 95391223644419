module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion\' style=\'margin-bottom: 0;\'>\n    <div class=\'accordion-group\'>\n        <div class=\'accordion-heading\'>\n            <div class=\'accordion-toggle\'>\n                <div class=\'svg_icon\'>\n                    ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n                </div>\n                ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n                <div class=\'wmui-btn\' id=\'edit_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.edit') )) == null ? '' : __t) +
'</div>\n            </div>\n        </div>\n        <div class=\'accordion-body\'>\n            <div class=\'accordion-inner\'>\n                <dl class=\'stepped zero\'>\n                    <dt>ID</dt>\n                    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('integrations.cpe') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( reference )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</dt>\n                    <dd><a href="' +
((__t = ( building.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'</a></dd>\n                    <dt>' +
((__t = ( i18next.t('glossary.reactive') )) == null ? '' : __t) +
'?</dt>\n                    <dd>' +
((__t = ( i18next.t(`common.${reactive ? 'yes' : 'no'}`).capitalize() )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('integrations.consent') )) == null ? '' : __t) +
'?</dt>\n                    <dd>' +
((__t = ( i18next.t(`common.${consent ? 'yes' : 'no'}`).capitalize() )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('integrations.integration_indicators') )) == null ? '' : __t) +
'</dt>\n                    ';
 integrationIndicators.forEach(function(indicator) { ;
__p += '\n                        <dd><a href="' +
((__t = ( indicator.nodeUrl() )) == null ? '' : __t) +
'">' +
((__t = ( indicator.get('name') )) == null ? '' : __t) +
'</a></dd>\n                    ';
 }) ;
__p += '\n                    <dt>' +
((__t = ( i18next.t('integrations.last_processed_file') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( last_processed_file || '-' )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('integrations.last_run_at') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( last_run_at ? WiseMetering.utils.formatDate(last_run_at) : '-' )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('common.creation') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( WiseMetering.utils.formatDate(created_at) )) == null ? '' : __t) +
' - ' +
((__t = ( WiseMetering.utils.getUserName(created_by) )) == null ? '' : __t) +
'</dd>\n                    <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n                    <dd>' +
((__t = ( WiseMetering.utils.formatDate(updated_at) )) == null ? '' : __t) +
' - ' +
((__t = ( WiseMetering.utils.getUserName(updated_by) )) == null ? '' : __t) +
'</dd>\n                </dl>\n            </div>\n        </div>\n    </div>\n</div>\n\n\n';

}
return __p
}