WiseMetering.Views.IntegrationLicenseTabs = WiseMetering.Views.Content.extend({
    icon: 'wiseStore',

    initialize({ model, view }) {
        this.name = this.model.partner().get('name');

        const views = this.viewsBySlug(model.get('partner_slug'));

        this.tabs = [];

        if (views.integrations) {
            this.tabs.push({
                active: view === 'integrations',
                href: `/integration_licenses/${model.id}/integrations`,
                id: 'integrations',
                name: i18next.t('glossary.integrations'),
                view: views.integrations
            });
        }

        if (views.configurations) {
            this.tabs.push({
                active: view === 'configurations',
                href: `/integration_licenses/${model.id}/configurations`,
                id: 'configurations',
                name: i18next.t('glossary.configurations'),
                view: views.configurations
            });
        }

        if (WiseMetering.currentUser.get('superuser')) {
            this.tabs.push({
                active: view === 'logs',
                href: `/integration_licenses/${model.id}/logs`,
                id: 'logs',
                name: i18next.t('glossary.logs'),
                view: WiseMetering.Views.IntegrationLogs
            });
        }

        this.tabs.push({
            active: view === 'licenses',
            href: `/integration_licenses/${model.id}/licenses`,
            id: 'licenses',
            name: i18next.t('glossary.licenses'),
            view: WiseMetering.Views.IntegrationLicensesTab
        });
    },

    viewsBySlug: function(slug) {
        switch (slug) {
            case 'contazara':
                return {
                    configurations: WiseMetering.Views.ContazaraConfigurationsTable,
                    integrations: WiseMetering.Views.ContazaraIntegrationsTable
                };
            case 'datelka':
                return {
                    configurations: WiseMetering.Views.DatelkaConfigurationsTable,
                    integrations: WiseMetering.Views.DatelkaIntegrationsTable
                };
            case 'degree_days':
                return {
                    integrations: WiseMetering.Views.DegreeDayIntegrationsTable
                };
            case 'e_redes':
                return {
                    integrations: WiseMetering.Views.ERedesIntegrationsTable
                };
            case 'electricity_maps':
                return {
                    integrations: WiseMetering.Views.ElectricityMapsIntegrationsTable
                };
            case 'infraspeak':
                return {
                    configurations: WiseMetering.Views.InfraspeakConfigurationsTable,
                    integrations: WiseMetering.Views.InfraspeakIntegrationsTable
                };
            case 'measurabl':
                return {
                    configurations: WiseMetering.Views.MeasurablConfigurationsTable,
                    integrations: WiseMetering.Views.MeasurablIntegrationsTable
                };
            case 'navigator':
                return {
                    configurations: WiseMetering.Views.NavigatorConfigurationsTable,
                    integrations: WiseMetering.Views.NavigatorIntegrationsTable
                };
            case 'nextbitt_maintenance':
                return {
                    configurations: WiseMetering.Views.NextbittMaintenanceConfigurationsTable,
                    integrations: WiseMetering.Views.NextbittMaintenanceIntegrationsTable
                };
            case 'nextbitt_data':
                return {
                    configurations: WiseMetering.Views.NextbittDataConfigurationsTable,
                    integrations: WiseMetering.Views.NextbittDataIntegrationsTable
                };
            case 'shopper_trak':
                return {
                    configurations: WiseMetering.Views.ShopperTrakConfigurationsTable,
                    integrations: WiseMetering.Views.ShopperTrakIntegrationsTable
                };
            case 'v2_charge':
                return {
                    configurations: WiseMetering.Views.V2CConfigurationsTable,
                    integrations: WiseMetering.Views.V2CIntegrationsTable
                };
            case 'waterbeep':
                return {
                    integrations: WiseMetering.Views.WaterbeepIntegrationsTable
                };
            case 'weather_api':
                return {
                    integrations: WiseMetering.Views.WeatherAPIIntegrationsTable
                };
            case 'data_portal':
                return {
                    configurations: WiseMetering.Views.DataPortalConfigurationsTable,
                    integrations: WiseMetering.Views.DataPortalIntegrationsTable
                };
            default:
                return {};
        }
    }
});
