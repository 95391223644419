module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_configuration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_name\' name=\'integration_configuration[name]\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_url\'>' +
((__t = ( i18next.t('glossary.base_url') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' name=\'integration_configuration[url]\' id=\'integration_configuration_url\' value="">\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_token\'>' +
((__t = ( i18next.t('glossary.token') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' name=\'integration_configuration[token]\' id=\'integration_configuration_token\' value="">\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}