module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.token') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( token )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.creation') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( WiseMetering.utils.formatDate(created_at) )) == null ? '' : __t) +
' - ' +
((__t = ( WiseMetering.utils.getUserName(created_by) )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.last_change') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( WiseMetering.utils.formatDate(updated_at) )) == null ? '' : __t) +
' - ' +
((__t = ( WiseMetering.utils.getUserName(updated_by) )) == null ? '' : __t) +
'</dd>\n</dl>\n';

}
return __p
}