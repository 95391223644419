module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="modal-description-container">\n    <span class="modal-description-title">\n        <i class="' +
((__t = ( WiseMetering.icons.info )) == null ? '' : __t) +
' modal-description-warning" style="margin-right: 10px; font-size: 2em;" aria-hidden=\'true\'></i>\n        <p>' +
((__t = ( i18next.t('ui.remove_extra_confirmation', { name }) )) == null ? '' : __t) +
'</p>\n    </span>\n    <p style="margin: 0;">' +
((__t = ( i18next.t('ui.remove_extra_confirmation_description', { name }) )) == null ? '' : __t) +
'</p>\n</div>\n<dd>\n    <input type="text" class=\'input-file text\' id="confirm-delete-input" placeholder="' +
((__t = ( i18next.t('ui.remove_extra_confirmation_placeholder') )) == null ? '' : __t) +
'">\n</dd>\n';

}
return __p
}