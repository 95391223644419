WiseMetering.Views.NewWaterbeepIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/waterbeep/new',
    ui: {
        buildings: '#integration_building_id'
    },
    title: () => i18next.t('modal.title.integrations.new'),

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onShow: function() {
        const buildings = WiseMetering.utils.optionsFromModels(WiseMetering.zones.activeSites().models, 'name');

        this.ui.buildings.html(buildings);
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        if (data.reference) {
            data.reference = parseInt(data.reference);
        }
        return data;
    }
});
