WiseMetering.Views.Modal.DestroyExtraConfirmation = Backbone.Marionette.ItemView.extend({
    buttons: ['yes', 'no'],
    template: 'templates/ui/table/remove_extra_confirmation',

    events: {
        'input #confirm-delete-input': 'validateInput',
    },

    initialize: function(attributes) {
        $.extend(this, attributes);
        this.modal = new WiseMetering.Views.Modal.Base({
            afterDestroy: this.afterDestroy,
            buttons: this.buttons,
            collection: this.collection,
            data: this.data,
            form_prefix: this.form_prefix,
            from: this.from,
            from_id: this.from_id,
            group: this.group,
            ids: this.ids,
            indicator_id: this.indicator_id,
            model: this.model,
            parent: this.parent,
            saveIt: this.saveIt,
            target: this.target,
            timestamps: this.timestamps,
            title: this.title,
            reset: this.reset,
            type: this.type,
            user: this.user,
            width: this.width,
            yes: this.yes,
        });
        WiseMetering.modalBox.show(this.modal);
        this.modal.body.show(this);
    },

    serializeData: function() {
        return {
            name: this.model?.get('name')
        };
    },

    validateInput: function() {
        const inputVal = $('#confirm-delete-input').val();
        const isValid = inputVal === 'DELETE';
        $('#yes').prop('disabled', !isValid);
    },

    onShow: function(){
        $('#yes').prop('disabled', true);
    },

    yes: function() {
        const inputVal = $('#confirm-delete-input').val();

        const that = this;
        that.block();
        const options = {
            wait: true,
            data: { delete_confirmation: ( inputVal === 'DELETE' ) },
            processData: true,
            success: function(model) {
                let complete = function() {
                    let text = i18next.t('modal.destroy.success');
                    if (model.get('name')) text += ' ' + model.get('name');
                    WiseMetering.layout.showTipper('success', text);
                    that.unblock();
                    that.close();
                };
                that.afterDestroy && typeof that.afterDestroy === 'function' ?
                    $.when(that.afterDestroy()).done(complete) : complete();
            },
            error: function(model, response) {
                let error = i18next.t('modal.destroy.error', { email: model.get('email') });
                try {
                    error = JSON.parse(response.responseText).error;
                } catch (e) {}
                WiseMetering.layout.showTipper('error', error);
                that.unblock();
                that.close();
            }
        };
        if (this.data && typeof this.data === 'function') {
            options.data = { ...this.data(), delete_confirmation: true };
        }
        that.model.destroy(options);
    }
});






