import { Icon } from '@/components/wm';
import * as React from 'react';

const ContentHeader = ({ icon, title }) => {
    return (
            <div className="tw-flex tw-items-center tw-mb-6">
                <Icon icon={icon} color="sideMenu"/>
                <h2 className="tw-inline-block tw-text-grey tw-ml-2">{title}</h2>
            </div>
    );
};

export { ContentHeader };
