module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_name\' name=\'integration[name]\'>\n        </dd>\n        <dt>\n            <label for=\'building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'indicator\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'indicator\' name=\'integration[indicator_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'configuration_id\'>' +
((__t = ( i18next.t('glossary.configuration') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'configuration_id\' name=\'integration[configuration_id]\'></select>\n        </dd>\n        <dd id=\'current-assets-list\'></dd>\n        <dt>\n            <label for="asset-search">' +
((__t = ( i18next.t('ui.search_keywords') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'asset-search\' placeholder=\'' +
((__t = ( i18next.t('ui.search_keywords_placeholder') )) == null ? '' : __t) +
'\' value="">\n        </dd>\n        <dt>\n            <label for="assets">' +
((__t = ( i18next.t('glossary.assets') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd id=\'assets-list\'>\n            <p>' +
((__t = ( i18next.t('integration_assets.search_first') )) == null ? '' : __t) +
'</p>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}