module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(info.length){ ;
__p += '\n    <div class=\'mbx-info green\' style=\'text-align: left;\'>\n        <b>\n            ' +
((__t = ( i18next.t('circuits.missing_info') )) == null ? '' : __t) +
':\n        </b>\n        ';
 _.each(info, function(text){ ;
__p += '\n            <br/>\n            <span style=\'padding-left: 20px;\'>\n            - ' +
((__t = ( text )) == null ? '' : __t) +
'\n            </span>\n        ';
 }) ;
__p += '\n        <br/>\n        <br/>\n        <span style="font-weight: bold; text-transform: capitalize;">' +
((__t = ( i18next.t('common.note') )) == null ? '' : __t) +
'>:</span>\n        <i>\n            ' +
((__t = ( i18next.t('circuits.post_fix_note') )) == null ? '' : __t) +
'\n        </i>\n        <br/>\n    </div>\n';
 } else { ;
__p += '\n    <div style=\'padding: 10px;\'>\n        <div class="grp_ctrl wmui-chart-toolbar" style="border-width:1px 0; border-style:solid; border-color:rgb(227, 227, 227); border-left:0; margin:10px; width: auto;">\n            <div class="wmui-chart-control-date_time">\n                <ul class="ctrl date_time-selector">\n                    <li class="label">' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
':</li>\n                    <li class="field border">\n                        <input class="date_time_from" maxlength="10" name="date-time-from" type="textfield" autocomplete="off"/>\n                    </li>\n                    <li class="label">' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
':</li>\n                    <li class="field border">\n                        <input class="date_time_to" maxlength="10" name="date-time-to" type="textfield" autocomplete="off"/>\n                    </li>\n                    <button class="submit_time">' +
((__t = ( i18next.t('ui.buttons.ok') )) == null ? '' : __t) +
'</button>\n                </ul>\n            </div>\n            <div class="loader" style="padding:0"></div>\n        </div>\n        <div id=\'data-tab-chart\'></div>\n        ';
 if(actuatorName) { ;
__p += '\n            <div class=\'wmui-table-view\'>\n                <table>\n                    <thead>\n                    <tr>\n                        <th>' +
((__t = ( i18next.t('circuits.actuator_connected') )) == null ? '' : __t) +
'</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n                    <tr>\n                        <td>' +
((__t = ( actuatorName )) == null ? '' : __t) +
'</td>\n                    </tr>\n                    </tbody>\n                </table>\n            </div>\n        ';
 } ;
__p += '\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}