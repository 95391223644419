WiseMetering.Views.NewMeasurablIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/measurabl/new',
    title: () => i18next.t('modal.title.integrations.new'),

    ui: {
        buildings: '#building_id',
        configurations: '#integration_configuration_id',
        indicators: '#integration_indicator_id',
        startFetchingAt: '#integration_start_fetching_at'
    },

    events: {
        'change @ui.buildings': 'onBuildingChange'
    },

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },


    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    },

    onBuildingChange: function() {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.configurations.empty();
            this.ui.indicators.empty();
            return;
        }

        const
            usedIndicatorsIds = this.model.license().indicators().pluck('id'),
            availableIndicators = WiseMetering.indicators.utilityIndicators().models.filter(indicator => !usedIndicatorsIds.includes(indicator.id) && indicator.get('building_id') === selectedBuildingId),
            indicators = WiseMetering.utils.optionsFromModels(availableIndicators, 'name', null, availableIndicators.length !== 1),
            configurations = WiseMetering.integrationConfigurations.where({ building_id: selectedBuildingId, license_id: this.model.license().id }),
            configurationOptions = WiseMetering.utils.optionsFromModels(configurations, 'name', null, configurations.length !== 1);

        this.ui.indicators.html(indicators);
        this.ui.configurations.html(configurationOptions);
    },

    onRender: function() {
        const maxRetention = WiseMetering.currentOrganization.tier().get('data_retention');

        this.ui.startFetchingAt.datepicker({
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true,
            maxDate: moment().endOf('year').format('YYYY-MM-DD'),
            minDate: moment().add(-maxRetention, 'years').startOf('year').format('YYYY-MM-DD')
        });
    },

    onShow: function() {
        const
            buildingsWithConfigurations = this.model.license().configurations().pluck('building_id'),
            buildings = WiseMetering.zones.activeSites().models.filter(building => buildingsWithConfigurations.includes(building.id));

        this.ui.buildings.html(WiseMetering.utils.optionsFromModels(buildings, 'name', null, buildings.length !== 1));
        this.onBuildingChange();
    }
});
