WiseMetering.Model.IntegrationConfiguration = WiseMetering.Model.extend({
    urlRoot: '/integration_configurations',

    building: function() {
        return WiseMetering.zones.get(this.get('building_id'));
    },

    license: function() {
        return WiseMetering.integrationLicenses.get(this.get('license_id'));
    }
});
