WiseMetering.Views.ERedesIntegrationFiles = WiseMetering.Views.TableIndex.extend({
    createTable: function(data = []) {
        const collection = new WiseMetering.Collection(data);

        this.table = new WiseMetering.Views.Table({
            id: 'files',
            collection,
            modelSerializer: model => {
                return {
                    id: model.id,
                    name: model.get('name'),
                    path: model.get('id'),
                    createdAt: WiseMetering.utils.formatDate(model.get('created_at')),
                    processed: i18next.t(`common.${model.get('processed') ? 'yes' : 'no'}`),
                    file_size: model.get('file_size_human'),
                };
            },
            columns: {
                name: i18next.t('common.name'),
                path: 'Path',
                createdAt: 'Creation Date',
                processed: 'Processed?',
                file_size: 'File Size'
            },
            toolbar_actions: {

                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => window.open(model.get('file_url'))
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.showLoader();

        $.ajax({
            url: `/integrations/${this.model.id}/files`,
            type: 'GET',
            contentType: 'application/json',
            success: function(data) {
                this.createTable(data);
            }.bind(this),
            error: function(err) {
                WiseMetering.layout.showTipper('error', 'unable to fetch files');
            }.bind(this)
        });
    }
});
