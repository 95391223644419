module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_configuration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_name\' name=\'integration_configuration[name]\' value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_username\'>' +
((__t = ( i18next.t('glossary.user') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_username\' name=\'integration_configuration[username]\' value=\'' +
((__t = ( username )) == null ? '' : __t) +
'\'>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_password\'>' +
((__t = ( i18next.t('common.password') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_password\' name=\'integration_configuration[password]\' placeholder="' +
((__t = ( i18next.t('ui.field_will_update') )) == null ? '' : __t) +
'" value="">\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}