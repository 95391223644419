import WiseStore from '@/pages/WiseStore';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.WiseStore = Backbone.Marionette.View.extend({
    id: 'react-container',
    className: 'tailwind-container',

    initialize: function(){
        WiseMetering.integrationLicenses.fetch();
    },

    integrationPartners: function() {
        return WiseMetering.integrationPartners.map(model => ({
            ...model.toJSON(),
            includedInTiers: model.tiers(), // Access the model's function
            tierRequirement: WiseMetering.tiers.get(model.get('tier_requirement_id'))?.get('name') || 'default value'
        }));
    },

    onShow: function() {
        this.listenTo(WiseMetering.integrationLicenses, 'add change remove', this.onCollectionChange.bind(this));

        const container = document.getElementById(this.id);

        if (!container) {
            console.error(`Element with id "${this.id}" not found.`);
            return;
        }

        this.reactRoot = createRoot(container);
        this.renderReactComponent();
    },

    onCollectionChange: function() {
        if (this.reactRoot) {
            this.renderReactComponent();
        }
    },

    renderReactComponent: function() {
        this.reactRoot.render(
            <WiseStore
                integrationPartners={this.integrationPartners()}
                licenses={WiseMetering.integrationLicenses.toJSON()}
                organizationId={WiseMetering.currentOrganization.get('id')}
            />
        );
    },

    onClose: function() {
        if (this.reactRoot) {
            this.reactRoot.unmount(); // Unmount the React component
            this.reactRoot = null; // Clean up reference
        }

        this.stopListening(WiseMetering.integrationLicenses);
    }
});


