WiseMetering.Views.Seu = WiseMetering.Views.Content.extend({
    initialize: function() {
        this.icon = this.model.icon();
        this.tabs = [
            {
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.SeuDetailsTab
            },
            {
                id: 'objectives',
                name: i18next.t('glossary.objectives'),
                view: WiseMetering.Views.SeuObjectives
            },
            {
                id: 'activities',
                name: i18next.t('common.activities'),
                view: WiseMetering.Views.Activities
            },
            {
                id: 'maintenance',
                name: i18next.t('common.maintenance'),
                view: WiseMetering.Views.MaintenanceActivities
            }
        ];
    }
});
