WiseMetering.Views.NewInfraspeakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/infraspeak/new',
    title: () => i18next.t('modal.title.integrations.new'),

    ui: {
        assetsList: '#assets-list',
        buildings: '#building_id',
        indicators: '#integration_indicator_id',
        configurations: '#configuration_id',
        name: 'input#integration_name',
        search: 'input#asset-search'
    },

    events: {
        'keyup input#asset-search': 'search',
        'change @ui.buildings': 'onBuildingChange'
    },

    initialize: function(options) {
        WiseMetering.Views.Modal.Save.prototype.initialize.call(this, options);

        this.partner_id = WiseMetering.integrationLicenses.get(this.model.get('license_id')).get('partner_id');
    },

    fetchExternalAssets: function(searchText) {
        this.modal.block();

        const payload = {
            license_id: this.model.get('license_id'),
            partner_id: this.partner_id,
            building_id: this.ui.buildings.val(),
            configuration_id: this.ui.configurations.val(),
            indicator_id: this.ui.indicators.val(),
            search_text: searchText
        };

        $.ajax({
            url: `/integrations/external_assets`,
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(payload),
            success: function(data) {
                const selectedElements = $('#assets-list').children('input:checked');

                selectedElements.each(function () {
                    const asset = $(this).add($(this).siblings());

                    asset.clone(true).appendTo('#current-assets-list');
                });

                this.ui.assetsList.empty();

                if (data.length > 0) {
                    data.forEach(function(asset, index) {
                        this.ui.assetsList.append(
                            `<input class="vertical-middle asset-checkbox"
                                    id="integration[assets_attributes][${index}]"
                                    name="integration[assets_attributes][]"
                                    type="checkbox"
                                    value="${asset.asset_reference}"
                                    data-name="${asset.name}"
                                    data-asset-reference="${asset.asset_reference}"
                                    data-local-reference="${asset.local_reference}">
                            </input>`,
                            `<label for='integration[assets_attributes][${index}]' style='margin-left: 5px'>${asset.name}</label><br/>`
                        );
                    }.bind(this));

                    $('#save').prop('disabled', false);
                } else {
                    this.ui.assetsList.html(`<p>${i18next.t('integration_assets.no_assets')}</p>`);
                }

                this.modal.unblock();
            }.bind(this),
            error: function(err) {
                const errors = err.responseJSON || null;
                let errorMessage = '';

                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        errorMessage += `${key.capitalize()}: ${errors[key].capitalize()} <br>`;
                    });
                } else {
                    errorMessage = 'An error occurred';
                }

                WiseMetering.layout.showTipper('error', errorMessage);
                this.modal.unblock();
            }.bind(this)
        });
    },

    getFormData: function() {
        let data = this.formSerializer();
        const assets_attributes = [];

        this.$('.asset-checkbox:checked').each(function() {
            const $asset = $(this);
            const asset_info = {
                name: $asset.data('name'),
                asset_reference: $asset.data('asset-reference'),
                local_reference: $asset.data('local-reference')
            };

            if (!assets_attributes.some(a => a.asset_reference === asset_info.asset_reference)) {
                assets_attributes.push(asset_info);
            }
        });

        data.assets_attributes = assets_attributes;
        data.partner_id = this.partner_id;

        return data;
    },

    onBuildingChange: function() {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.configurations.empty();
            this.ui.indicators.empty();
            return;
        }

        const
            usedIndicatorsIds = this.model.license().indicators().pluck('id'),
            availableIndicators = WiseMetering.indicators.models.filter(indicator => !usedIndicatorsIds.includes(indicator.id) && indicator.get('building_id') === selectedBuildingId),
            indicators = WiseMetering.utils.optionsFromModels(availableIndicators, 'name', null, availableIndicators.length !== 1),
            configurations = WiseMetering.integrationConfigurations.where({ building_id: selectedBuildingId, license_id: this.model.license().id }),
            configurationOptions = WiseMetering.utils.optionsFromModels(configurations, 'name', null, configurations.length !== 1);

        this.ui.indicators.html(indicators);
        this.ui.configurations.html(configurationOptions);
    },

    onShow: function() {
        const
            buildingsWithConfigurations = this.model.license().configurations().pluck('building_id'),
            buildings = WiseMetering.zones.activeSites().models.filter(building => buildingsWithConfigurations.includes(building.id));

        this.ui.buildings.html(WiseMetering.utils.optionsFromModels(buildings, 'name', null, buildings.length !== 1));
        this.onBuildingChange();
    },

    search: function(event) {
        if (event && (event.keyCode || event.which) !== 13) {
            return;
        }

        if (this.ui.search.val().length < 3) {
            return;
        }

        this.fetchExternalAssets(this.ui.search.val());
    }
});
