module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'widget\'>\n    <div class=\'title\'>\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n    </div>\n    <div class=\'content value-displayer\'>\n        <div class="value">\n            <i class="' +
((__t = ( WiseMetering.icons['carbonFootprint'] )) == null ? '' : __t) +
'"></i>\n            ' +
((__t = ( data.value )) == null ? '' : __t) +
'\n        </div>\n\n    </div>\n</div>\n';

}
return __p
}