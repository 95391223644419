WiseMetering.Views.ShopperTrakIntegrationDetails = Backbone.Marionette.ItemView.extend({
    className: 'dashboard full-height',
    template: 'templates/integrations/shopper_trak/show/details',

    modelEvents: {
        'change': 'render'
    },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            configurationName: this.model.configuration().get('name'),
            indicator: this.model.indicator(),
            integrationIndicators: this.model.integrationIndicators()
        };
    }
});
