WiseMetering.Views.ShowContazaraIntegration = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/integrations/contazara/show',
    title: () => i18next.t('modal.title.integrations.show'),

    // edit: function() {
    //     new WiseMetering.Views.EditContazaraIntegration({ model: this.model });
    // },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            configurationName: this.model.configuration().get('name'),
            indicatorKindName: this.model.indicatorKind().get('name'),
            integrationIndicators: this.model.integrationIndicators()
        };
    }
});
