module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_configuration_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'integration_configuration_name\' name=\'integration_configuration[name]\'>\n        </dd>\n        <div class="modal-description-container">\n                <span class="modal-description-title">\n                    <i class="' +
((__t = ( WiseMetering.icons.info )) == null ? '' : __t) +
'" style="margin-right: 10px; color: #2a81c0; font-size: 2em;" aria-hidden=\'true\'></i>\n                </span>\n            <p style="margin: 0">You\'ll be able to see the token after creating the configuration.</p>\n        </div>\n    </dl>\n</form>\n';

}
return __p
}