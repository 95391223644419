WiseMetering.Views.ShowInfraspeakIntegration = WiseMetering.Views.Modal.Show.extend({
    className: 'mbx-info text-left',
    template: 'templates/integrations/infraspeak/show',
    title: () => i18next.t('modal.title.integrations.show'),

    // edit: function() {
    //     new WiseMetering.Views.EditInfraspeakIntegration({ model: this.model });
    // },

    serializeData: function() {
        return {
            ...this.model.toJSON(),
            building: this.model.building(),
            configurationName: this.model.configuration().get('name'),
            indicator: this.model.indicator(),
            integrationAssets: this.model.get('assets')
        };
    }
});
