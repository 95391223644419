import { Button, Card, CardContent, CardDescription, CardFooter, CardHeaderImage, CardText, Icon, ManageLicense, ModelProperty } from '@/components/wm';
import * as React from 'react';
import { useState } from 'react';

export function Partner({ partner, license, organizationId }) {
    const
            [modalOpen, setModalOpen] = useState(false),
            image = `/images/integration-partners/${partner.slug}.png`,
            slug = partner.slug,
            state = partner.state;

    const determineButtonState = () => {
        if (state === 'active') {
            if (WiseMetering.currentUser.isSuperUser()) {
                if (!license) {
                    return { label: i18next.t('integrations.licenses.activate_license'), disabled: false, onClick: () => createLicense() };
                }
                if (license && license.state === 'request') {
                    return { label: i18next.t('integrations.licenses.requested'), disabled: false, onClick: () => navigateToLicense() };
                }
                if (license && license.state === 'active') {
                    return { label: i18next.t('ui.actions.manage'), disabled: false, onClick: () => navigateToLicense() };
                }
            }

            if (WiseMetering.currentUser.isAdmin()) {
                if (!license) {
                    return { label: i18next.t('integrations.licenses.request_activation'), disabled: false, onClick: () => createLicense() };
                }
                if (license && license.state === 'request') {
                    return { label: i18next.t('integrations.licenses.activation_requested'), disabled: true };
                }
                if (license && license.state === 'active') {
                    return { label: i18next.t('ui.actions.manage'), disabled: false, onClick: () => navigateToLicense() };
                }
            }

            return { label: i18next.t('ui.actions.activate'), disabled: false };
        }

        return { label: i18next.t('integrations.licenses.upcoming'), disabled: true };
    };

    const
        buttonState = determineButtonState(),
        createLicense = () => setModalOpen(true),
        navigateToLicense = () => Backbone.history.navigate(`/integration_licenses/${license.id}/${license.total === 0 ? 'licenses' : 'integrations'}`, { trigger: true });

    let modelPropertyTotal = <ModelProperty property="total" value={0} type="hidden"/>;
    if (WiseMetering.currentUser.isSuperUser()) {
        modelPropertyTotal = <ModelProperty dataType="int" label={i18next.t('integrations.licenses.total')} property="total" type="number"/>;
    }

    let description = i18next.t('integrations.licenses.modal_description');
    if (WiseMetering.currentUser.isSuperUser()) {
        description = '';
    }

    return (
            <div>
                {modalOpen && (
                        <ManageLicense
                                description={description}
                                model={license}
                                mode={license ? 'edit' : 'create'}
                                onClose={() => setModalOpen(false)}
                                title={i18next.t('integrations.licenses.activate_license')}
                        >
                            {modelPropertyTotal}
                            <ModelProperty label={
                                WiseMetering.currentUser.isSuperUser() ?
                                i18next.t('integrations.licenses.activation_reason') :
                                i18next.t('common.comment').capitalize()
                            } property="comment" type="text"/>
                            <ModelProperty property="partner_id" value={partner.id} type="hidden"/>
                            <ModelProperty property="organization_id" value={organizationId} type="hidden"/>
                        </ManageLicense>
                )}
                <Card className="tw-h-full">
                    <CardHeaderImage>
                        <img src={image} alt={partner.name}/>
                    </CardHeaderImage>
                    <CardDescription>
                    <span>
                        {partner.name}
                    </span>
                        <span>
                        {partner.homepage && (
                                <a href={partner.homepage} target="_blank" rel="noopener noreferrer">
                                    <Button variant="ghost" size="socialIcon">
                                        <Icon icon="globe"/>
                                    </Button>
                                </a>
                        )}
                            {partner.linkedin && (
                                    <a href={partner.linkedin} target="_blank" rel="noopener noreferrer">
                                        <Button variant="ghost" size="socialIcon">
                                            <Icon icon="linkedIn"/>
                                        </Button>
                                    </a>
                            )}
                    </span>
                    </CardDescription>
                    <CardContent>
                        <CardText title={i18next.t('common.description').capitalize()}>
                            {i18next.t(`integration_partners.partners.${slug}.description`)}
                        </CardText>
                        <CardText title={i18next.t('common.benefits').capitalize()}>
                            {i18next.t(`integration_partners.partners.${slug}.benefits`)}
                        </CardText>
                        <CardText title={i18next.t('integration_partners.default_tier').capitalize()}>
                            {partner.includedInTiers}
                        </CardText>
                        <CardText title={i18next.t('integration_partners.tier_requirement').capitalize()}>
                            {partner.tierRequirement.capitalize()}
                        </CardText>
                    </CardContent>
                    <CardFooter>
                        <Button
                                type="button"
                                className="tw-w-full"
                                disabled={buttonState.disabled}
                                onClick={buttonState.onClick}
                        >
                            {buttonState.label}
                        </Button>
                    </CardFooter>
                </Card>
            </div>
    );
}
