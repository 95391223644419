module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_indicator_id\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_indicator_id\' name=\'integration[indicator_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_configuration_id\'>' +
((__t = ( i18next.t('glossary.configuration') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_configuration_id\' name=\'integration[configuration_id]\'></select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}