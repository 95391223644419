module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'building_id\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'building_id\' name=\'integration[building_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_indicator_id\'>' +
((__t = ( i18next.t('glossary.indicator') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_indicator_id\' name=\'integration[indicator_id]\'></select>\n        </dd>\n        <dt>\n            <label for=\'integration_emission_factor_type\'>' +
((__t = ( i18next.t('integrations.emission_factor_type') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'integration_emission_factor_type\' name=\'integration[emission_factor_type]\'>\n                <option value="lifecycle">' +
((__t = ( i18next.t('integration_partners.partners.electricity_maps.lifecycle') )) == null ? '' : __t) +
'</option>\n                <option value="direct">' +
((__t = ( i18next.t('integration_partners.partners.electricity_maps.direct') )) == null ? '' : __t) +
' (Not recommended)</option>\n            </select>\n            <div id="emission-factor-container" class="modal-description-container">\n                <span class="modal-description-title">\n                    <i class="' +
((__t = ( WiseMetering.icons.info )) == null ? '' : __t) +
'" style="margin-right: 10px; color: #2a81c0; font-size: 2em;" aria-hidden=\'true\'></i>\n                    <p id="emission-factor-title"></p>\n                </span>\n                <p id="emission-factor-description" style="margin: 0;"></p>\n            </div>\n        </dd>\n\n    </dl>\n</form>\n';

}
return __p
}