WiseMetering.Views.TableIndex = WiseMetering.Views.Content.extend({
    onShow: function() {
        this.table = this.createTable();
        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    showLoader: function() {
        const loader = '<img class="componentLoading" src="/images/wisemetering/loader.svg" style="margin: 0 auto; max-height: 50px; width: 100%;">';
        $(this.body.el).html(loader);
    },
});
