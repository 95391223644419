WiseMetering.Views.NewShopperTrakIntegration = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'integration',
    template: 'templates/integrations/shopper_trak/new',
    title: () => i18next.t('modal.title.integrations.new'),
    ui: {
        buildings: '#building_id',
        configurations: '#integration_configuration_id',
        indicators: '#integration_indicator_id'
    },

    events: {
        'change @ui.buildings': 'onBuildingChange'
    },

    afterSave: function() {
        WiseMetering.indicators.fetch();
    },

    onBuildingChange: function() {
        const selectedBuildingId = this.ui.buildings.val();

        if (!selectedBuildingId) {
            this.ui.configurations.empty();
            this.ui.indicators.empty();
            return;
        }

        const
            usedIndicatorsIds = this.model.license().indicators().pluck('id'),
            availableIndicators = WiseMetering.indicators.utilityIndicators().models.filter(indicator => !usedIndicatorsIds.includes(indicator.id) && indicator.get('building_id') === selectedBuildingId),
            indicators = WiseMetering.utils.optionsFromModels(availableIndicators, 'name', null, availableIndicators.length !== 1),
            configurations = WiseMetering.integrationConfigurations.where({ building_id: selectedBuildingId, license_id: this.model.license().id }),
            configurationOptions = WiseMetering.utils.optionsFromModels(configurations, 'name', null, configurations.length !== 1);

        this.ui.indicators.html(indicators);
        this.ui.configurations.html(configurationOptions);
    },

    onShow: function() {
        const
            buildingsWithConfigurations = this.model.license().configurations().pluck('building_id'),
            buildings = WiseMetering.zones.activeSites().models.filter(building => buildingsWithConfigurations.includes(building.id));

        this.ui.buildings.html(WiseMetering.utils.optionsFromModels(buildings, 'name', null, buildings.length !== 1));
        this.onBuildingChange();
    },

    getFormData: function() {
        const data = this.formSerializer();
        data.partner_id = this.options.license.get('partner_id');
        return data;
    }
});
