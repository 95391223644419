import { cn } from '@/utils';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import { useEffect } from 'react';

const containerVariants = cva(
        'tw-relative tw-transition-all tw-p-6 tw-overflow-hidden',
        {
            variants: {
                variant: {
                    full: 'tw-bg-white tw-rounded-md tw-w-[90%] tw-max-w-screen-2xl tw-mx-auto tw-flex tw-h-full tw-flex-1',
                    half: 'tw-w-1/2 tw-mx-auto'
                }
            },
            defaultVariants: {
                variant: 'full'
            }
        }
);

const Container = React.forwardRef(
        ({ className, variant, children, deps = [], ...props }, ref) => {

            useEffect(() => {
                // Set the scrollbar-gutter when the component mounts
                const panelsContainer = $('.panels_container');

                if (!panelsContainer) {
                    return;
                }

                panelsContainer.css('scrollbar-gutter', 'stable');

                // Cleanup the style when the component unmounts
                return () => {
                    panelsContainer.css('scrollbar-gutter', '');
                };

            }, []);

            return (
                    <div className="tw-min-h-[calc(100vh-50px)] tw-flex tw-justify-center tw-items-start tw-flex-col tw-py-10 tw-bg-grey-bg">
                        <div
                                className={cn(containerVariants({ variant, className }))}
                                ref={ref}
                                {...props}
                        >
                            {children}
                        </div>
                    </div>
            );
        }
);

Container.displayName = 'Container';

export { Container, containerVariants };
