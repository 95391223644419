import IntegrationLicenses from '@/pages/Integrations/Licenses';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.IntegrationLicensesTab = Backbone.Marionette.View.extend({
		id: 'react-container',
		className: 'tailwind-container',

		initialize: function() {
				this.listenTo(WiseMetering.integrationLicenses, 'add change remove', this.onCollectionChange.bind(this));
		},

		onShow: function() {
				const container = document.getElementById(this.id);

				if (!container) {
						console.error(`Element with id "${this.id}" not found.`);
						return;
				}

				this.reactRoot = createRoot(container);
				this.renderReactComponent();
		},

		onCollectionChange: function() {
				this.renderReactComponent();
		},

		renderReactComponent: function() {
				this.model.fetch().then(() => {
						WiseMetering.integrationLicenseLogs.fetch().then(() => {
								const licenseLogs = WiseMetering.integrationLicenseLogs.where({ license_id: this.model.get('id') })
										.map(log => log.toJSON())
										.sort((a, b) => a.created_at > b.created_at ? -1 : 1);

								this.reactRoot.render(
										<IntegrationLicenses
												licenses={this.model.toJSON()}
												licenseLogs={licenseLogs}
										/>
								);
						});
				});
		},

		onClose: function() {
				if (this.reactRoot) {
						this.reactRoot.unmount(); // Unmount the React component
						this.reactRoot = null; // Clean up reference
				}
				this.stopListening(WiseMetering.integrationLicenses);
		}
});
