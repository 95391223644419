module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'integration_consent\'>' +
((__t = ( i18next.t('integrations.consent') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <input ' +
((__t = ( consent ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'integration_consent\' name=\'integration[consent]\' type=\'checkbox\'>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}